
import TitleReturn from '@/components/TitleReturn.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';

import CustomerEntityModule from '@/store/customers/entity';
import CustomersModule from '@/store/customers';
import CustomerShopsModule from '@/store/customers/shops';

import AppTable from '@/components/ui/table/Table.vue';
import TextDatetime from '@/components/table-items/TextDatetime.vue';
import TextPhone from '@/components/table-items/TextPhone.vue';
import StatusActive from '@/components/table-items/StatusActive.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import { Component, Vue } from '@/lib/decorator';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { TableInterface } from '@/lib/layouts/page/table.interface';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    AppCol,
    AppRow,
    StatusActive,
    TextPhone,
    TextDatetime,
    AppTable,
    TitleReturn,
    TabsNav,
  },
  beforeMount() {
    this.customerId = this.$route.params.customerId as string;
  },
  mounted() {
    CustomerEntityModule.initTabsItems();

    CustomerEntityModule.initTitle(this.customerId);
    CustomerShopsModule.initListById(this.customerId);
  },
})
export default class CustomerShop extends Vue {
  customerId = '';

  get isTest(): boolean {
    return CustomerEntityModule.model.isTest;
  }

  get settings(): PageInterface {
    return CustomerShopsModule.pageSettings;
  }

  get table(): TableInterface | {} {
    return CustomerShopsModule.pageSettings.table;
  }

  get titlePage(): string {
    return CustomersModule.pageSettings.titleEdit;
  }
  get tabsNav(): TabsNavInterface {
    return CustomerEntityModule.tabsNav;
  }

  get currentTab(): string {
    return CustomerEntityModule.allTabs.shops?.id;
  }

  returnBefore(): void {
    this.$router.push({ name: 'customers' });
  }

  toAdd(): void {
    this.$router.push({ name: 'customers_shop_add', params: { customerId: this.customerId } });
  }

  selectAmount(value: string): void {
    CustomerShopsModule.updatePageAmountItems(value);
  }

  async clearSort(): Promise<void> {
    CustomerShopsModule.clearSort();
    await CustomerShopsModule.initListById(this.customerId);
  }

  async sort(header: { id: string; sort: { value: string } }): Promise<void> {
    await CustomerShopsModule.sort({ field: header.id, sort: header.sort.value || '' });
    await CustomerShopsModule.initListById(this.customerId);
  }
}
