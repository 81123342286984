/**
 * Customer shops store.
 *
 */

import { Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { tabsNav } from './entityTabs';

import { prepareList } from '@/lib/Shop';
import { getShopsListById } from '@/api/shop';
import ResponseHandlerModule from '@/store/modules/responseHandler';

export const MODULE_NAME = 'customerShops';
import { PageSort } from '@/lib/layouts/page/page.interface';
import { TableInterface } from '@/lib/layouts/page/table.interface';
import { strings } from '@/lib/stringConst';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class CustomerShopsModule extends PageBaseModule {
  customerId = '';
  tabsNav = tabsNav;

  constructor(module: CustomerShopsModule) {
    super(module);

    const page = new PageEntity();
    page.setTitleAdd('Добавление объекта');
    page.values.actionPagination = 'customerShops/updatePage';
    this.pageSettings = page.values;
  }

  @Mutation
  SET_CUSTOMER_ID(id: string) {
    this.customerId = id;
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.customerShopsSort = JSON.stringify(sorts);
  }

  @Action({ rawError: true })
  async initListById(customerId: string) {
    await this.context.commit('SET_CUSTOMER_ID', customerId);
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);
    await this.getListById(customerId);
  }

  @Action({ rawError: true })
  async getListById(customerId: string) {
    try {
      let sort = '';
      const sortData: Record<string, { key: string; sort: string }> = window.localStorage.customerShopsSort
        ? JSON.parse(window.localStorage.customerShopsSort)
        : this.pageSettings.sort;

      let index: string;
      let item: { key: string; sort: string };
      for ([index, item] of Object.entries(sortData)) {
        sort += `sort[${index}][id]=${item.key}&sort[${index}][value]=${item.sort}&`;
      }
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const result: TableInterface = await getShopsListById(this.pageSettings.pageCurrent, customerId, itemsQuery, sort);
      await this.setList(result);
    } catch (error) {
      if (error.response?.data?.errors) {
        ResponseHandlerModule.showNotify({ message: error.response?.data?.errors, type: 'fail' });

        return;
      }

      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async getList() {
    await this.getListById(this.customerId);
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', { moduleName: MODULE_NAME, amountItems: number });
    await this.getListById(this.customerId);
  }

  @Action({ rawError: true })
  async sort(params: { field: string; sort: string }) {
    const sortProcessed = await this.context.dispatch('sortProcessed', params);
    this.context.commit('SET_SORT', sortProcessed);
  }

  @Action({ rawError: true })
  clearSort() {
    this.context.commit('SET_SORT', {});
  }

  @Action({ rawError: true })
  setList(table: TableInterface) {
    const sort = Object.values(this.pageSettings.sort);
    this.context.commit('SET_TABLE', prepareList(table, { sort }));
  }
}

export default getModule(CustomerShopsModule);
