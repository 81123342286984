export const tabsNav = {
  shift: {
    id: 'shift',
    name: 'Заказы',
    template: 'shift',
    pathName: 'customers_shop_shift',
  },
  statistics: {
    id: 'statistics',
    name: 'Статистика по исполнителям',
    template: 'statistics',
    pathName: 'customers_shop_statistics',
  },
  profile: {
    id: 'profile',
    name: 'Профиль',
    template: 'vacancies',
    pathName: 'customers_shop_edit',
  },
};
